import { Dispatch, SetStateAction } from 'react';

import { IInputData } from '@/interfaces/IInputData';

type SetInputData<TInputData extends IInputData> = Dispatch<SetStateAction<TInputData>>;

export function setFieldFactory<TInputData extends IInputData>(setInputData: SetInputData<TInputData>) {
  return (name: string, value: number | boolean, option = false): void => {
    if (option) {
      const section = typeof value === 'boolean' ? 'flag' : 'value';

      setInputData((prev: TInputData) => ({
        ...prev,
        options: {
          ...prev.options,
          [name]: {
            ...prev.options?.[name],
            [section]: value,
          },
        },
      }));
    } else {
      const section = typeof value === 'boolean' ? 'flags' : 'values';

      setInputData((prev: TInputData) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [name]: value || 0,
        },
      }));
    }
  };
}
