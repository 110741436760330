import type { IInputData } from '@/interfaces/IInputData';
import type { ILegacyInputData } from '@/interfaces/ILegacyInputData';
import type { IPropertyOrders } from '@/interfaces/IPropertyOrders';
import { parseV2 } from './v2';
import { parseV1 } from './v1';
import { parseV3 } from './v3';
import type { Brand } from '../../enums/Brand';
import { parseV4 } from './v4';
import { propertyOrders as croatiaPropertyOrders } from '../../components/features/Croatia/constants/defaults';
import { HrkToEuro } from '../../constants/euro';
import { NUMERIC_VALUES } from './constants';

export function toLegacyInput(propertyOrders: IPropertyOrders, data: ILegacyInputData | IInputData): ILegacyInputData {
  if ('age' in data) {
    return data;
  }

  return {
    ...(data.flags || {}),
    ...(data.values || {}),
    optimisations: propertyOrders.options?.map((prop) => {
      return { type: prop, amount: data.options?.[prop]?.value ?? 0, enabled: data.options?.[prop]?.flag ?? false };
    }),
  } as ILegacyInputData;
}

export function fromLegacyInput(
  propertyOrders: IPropertyOrders,
  data: ILegacyInputData | IInputData,
  defaults?: IInputData,
): IInputData {
  if (!('age' in data)) {
    return data;
  }

  return toEur({
    flags: (propertyOrders.flags ?? []).reduce((acc: Record<string, boolean>, key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      acc[key] = (data as any)[key] ?? defaults?.flags?.[key];

      return acc;
    }, {}),
    values: propertyOrders.values.reduce((acc: Record<string, number>, key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      acc[key] = (data as any)[key] ?? defaults?.values?.[key];

      return acc;
    }, {}),
    options: propertyOrders.options?.reduce(
      (
        acc: Record<
          string,
          {
            value: number;
            flag: boolean;
          }
        >,
        prop,
      ) => {
        acc[prop] = {
          flag: data.optimisations.find((item) => item.type === prop)?.enabled ?? false,
          value: data.optimisations.find((item) => item.type === prop)?.amount ?? 0,
        };

        return acc;
      },
      {},
    ),
  });
}

export function toEur<TInputData extends IInputData>(input: TInputData): TInputData {
  const valuesArr = Object.entries(input.values ?? {});
  const eurValues = Object.fromEntries(
    valuesArr?.map(([key, value]) => (NUMERIC_VALUES.includes(key) ? [key, value] : [key, value / HrkToEuro])),
  );

  const optionsArr = Object.entries(input.options ?? {});
  const eurOptions = Object.fromEntries(
    optionsArr?.map(([key, value]) => [key, { flag: value.flag, value: value.value / HrkToEuro }]),
  );

  return {
    ...input,
    values: eurValues,
    options: eurOptions,
  };
}

export function parseFromUrl<TInputData extends IInputData>(
  propertyOrders: IPropertyOrders,
  defaults: TInputData,
  brand: Brand,
  offerFields?: ReadonlyArray<string>,
  data: string = window.location.hash.slice(1),
): TInputData {
  const str = data;

  try {
    if (!str) {
      return defaults as TInputData;
    } else if (str.startsWith('d')) {
      const defs = fromLegacyInput(propertyOrders, defaults);
      const isCroatia = propertyOrders === croatiaPropertyOrders;
      const parsed = parseV3(propertyOrders, str, brand, defs, offerFields) as TInputData;

      return isCroatia ? toEur(parsed) : parsed;
    } else if (str.startsWith('c')) {
      const defs = toLegacyInput(propertyOrders, defaults);

      return fromLegacyInput(propertyOrders, parseV2(str, defs), defaults) as TInputData;
    } else if (str.startsWith('ey')) {
      const defs = toLegacyInput(propertyOrders, defaults);

      return fromLegacyInput(propertyOrders, parseV1(str, defs), defaults) as TInputData;
    } else if (str.startsWith('f')) {
      return parseV4(propertyOrders, str.slice(1), brand, defaults, offerFields) as TInputData;
    }
  } catch (e) {
    console.log(new Error('The url is in an unsupported format'), str, e);

    // return defaults as TInputData;
    throw e;
  }

  return defaults as TInputData;
}

export { serializeV3, getOfferCountry as getOfferCountryV3 } from './v3';

export { serializeV4 as serializeData, getOfferCountry } from './v4';
