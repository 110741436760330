import { countries } from '@/constants/countries';
import { OptionOfferState } from '@/contexts/OfferContext';
import { IInputData } from '@/interfaces/IInputData';
import { IPayload } from '@/interfaces/IPayload';
import { IPropertyOrders } from '@/interfaces/IPropertyOrders';
import { Country } from '@/interfaces/Country';
import { Brand } from '@/enums/Brand';

const isDev = process.env.NODE_ENV === 'development';

const shortenMap: Record<string, string> = { 2: ',,', 3: ',,,' };
const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ456789'.split('');
const charsCount = chars.length;
const ZERO_PADDING_CHAR = '-';
// Unused safe chars: =_!/;

function mapObjectsToArrays<TItem>(propertyOrders: Array<string> = [], data: Record<string, TItem>): Array<TItem> {
  return propertyOrders.map((key) => data[key]).filter((item) => item !== undefined);
}

function compressNumbersV3(...numbers: Array<number | string>): string {
  return numbers.join(',');
}

function encodeNumber(num: number): string {
  let code = '';
  let inNum = num;

  do {
    code = chars[inNum % charsCount] + code;
    inNum = Math.floor(inNum / charsCount);
  } while (inNum);

  return code;
}

function decodeNumber(code: string): number {
  let num = 0;

  for (let i = 0; i < code.length; i++) {
    num = num * charsCount + chars.indexOf(code[i]);
  }

  return num;
}

function compressFlags(...flags: Array<boolean>): number {
  return flags?.length ? parseInt(flags.map(Number).join(''), 2) : 0;
}

function decompressFlags(flags: number, count: number): Array<boolean> {
  return flags
    .toString(2)
    .padStart(count, '0')
    .split('')
    .map((val) => val === '1');
}

function segmentArray(arr: Array<unknown>, size: number): Array<Array<unknown>>;
function segmentArray(arr: string, size: number): Array<string>;
function segmentArray(arr: string | Array<unknown>, size: number): Array<Array<unknown> | string> {
  const result = [];

  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }

  return result;
}

function mapArraysToObjects<TItem>(
  propertyOrders: Array<string> = [],
  values: Array<TItem>,
  defs: Record<string, TItem> = {},
): Record<string, TItem> {
  return propertyOrders.reduce(
    (acc: Record<string, TItem>, key, i) => ({
      ...acc,
      ...(defs[key] != undefined && { [key]: values[i] ?? defs[key] }),
    }),
    {},
  );
}

function compressOptions(...opts: Array<{ value: number; flag: boolean }>): string {
  return compressNumbersV3(compressFlags(...opts.map((o) => o.flag)), ...opts.map((o) => o.value));
}

const KEYS: Record<Brand, Array<number>> = {
  [Brand.Infinum]: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 42],
  [Brand.PDC]: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43],
  [Brand.Productive]: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 44],
};

/**
 * Generates signature for the data string
 * @param str Generated data string
 * @param brand Brand of the page
 * @returns Signature matching the input string
 */
function getSignatureV3(str: string, brand: Brand): string {
  const match = str.match(/[a-zA-Z0-9]/g);
  const code = match ? match.join('') : '';
  const summary = decodeNumber(code);
  const signature = KEYS[brand].map((num, i) => (summary * (i + 1)) % num).reduce((a, c) => a + c);

  return encodeNumber(signature % charsCount);
}

function inputToPayload(propertyOrders: IPropertyOrders, input: IInputData): IPayload {
  const result: IPayload = {
    flags: mapObjectsToArrays(propertyOrders.flags, input.flags ?? {}),
    values: mapObjectsToArrays(propertyOrders.values, input.values ?? {}),
    options: mapObjectsToArrays(propertyOrders.options, input.options ?? {}),
  };

  return result;
}

function payloadToInput(propertyOrders: IPropertyOrders, payload: IPayload, defaults: IInputData): IInputData {
  const result: IInputData = {
    flags: mapArraysToObjects(propertyOrders.flags, payload.flags ?? [], defaults.flags),
    values: mapArraysToObjects(propertyOrders.values, payload.values ?? [], defaults.values),
    options: mapArraysToObjects(propertyOrders.options, payload.options ?? [], defaults.options),
  };

  return result;
}

export function getOfferCountry(data: string = window.location.hash.slice(1)) {
  const offerSegment = data.split(/0/g)[2];
  const countryCode = offerSegment?.slice(0, 1);
  const countryIndex = chars.indexOf(countryCode);

  return countries[countryIndex];
}

export function serializeV3<TInputData extends IInputData>(
  propertyOrders: IPropertyOrders,
  state: TInputData,
  setKey: (key: string, value: string) => void,
  brand: Brand,
  country?: Country,
  offerState?: Record<string, OptionOfferState>,
  offerFields: ReadonlyArray<string> = [],
): string {
  const payload = inputToPayload(propertyOrders, state);
  const params = [
    compressNumbersV3(3, compressFlags(...(payload.flags ?? [])), ...(payload.values ?? [])),
    compressOptions(...(payload.options || [])),
  ];

  if (offerState) {
    params.push(
      [
        countries.findIndex((c) => c.key === country),
        parseInt(offerFields.map((name) => offerState[name].toString(2).padStart(2, '0')).join(''), 2).toString(),
      ].join(),
    );
  }

  let str = params
    .join('|')
    .replace(/,0/g, ',')
    .replace(/\d+/g, (numStr: string) => {
      const pad = numStr.length > 1 ? numStr.match(/^0+/)?.[0]?.length ?? 0 : 0;

      return ''.padStart(pad, ZERO_PADDING_CHAR) + encodeNumber(parseInt(numStr, 10));
    });
  // .replace(/,+$/, '');

  if (!offerState && str.endsWith('|a')) {
    str = str.slice(0, -2);
  }

  if (isDev) {
    console.log('expanded query', str);
  }
  Object.entries(shortenMap)
    .reverse()
    .forEach(([key, value]) => {
      str = str.replace(new RegExp(value, 'g'), key.split('').pop() || '');
    });
  str = str.replace(/,/g, '1').replace(/\|/g, '0');
  str += getSignatureV3(str, brand);

  // TODO: is this OK, or should country have a default value so that adding the key is not skipped?
  if (country) {
    setKey(country, str);
  }

  return str;
}

export function parseV3(
  propertyOrders: IPropertyOrders,
  data: string,
  brand: Brand,
  defs: IInputData,
  offerFields?: ReadonlyArray<string>,
): IInputData {
  let str = data.slice(0, -1);

  if (!isDev && getSignatureV3(str, brand) !== data[data.length - 1]) {
    throw new Error('Invalid query string');
  }

  str = str.replace(/1/g, ',').replace(/0/g, '|');
  Object.entries(shortenMap).forEach(([key, value]) => {
    str = str.replace(new RegExp(key, 'g'), value);
  });
  str = str.replace(new RegExp(ZERO_PADDING_CHAR, 'g'), '0');
  str = str.replace(/[a-zA-Z4-9]+/g, (match: string) => decodeNumber(match).toString());
  str = str.replace(/,{2,}/g, (match) => match.split('').join('0'));

  if (isDev) {
    console.log('expanded query', str);
  }

  const [values, opts, [_country, offerData] = [0, 0]] = str.split('|').map((s) => (s ?? '0').split(',').map(Number));

  const [_v, flags, ...numbers] = values;
  const params: IPayload = {};

  params.flags = decompressFlags(flags, Object.keys(defs.flags ?? []).length);
  params.values = numbers;
  const [optFlags, ...optVals] = opts || [];

  params.options = optVals.length
    ? decompressFlags(optFlags, optVals?.length || 0).map((flag, i) => ({
        flag,
        value: optVals[i] || 0,
      }))
    : [];

  let offer = undefined;

  if (offerFields) {
    const offerFlags = offerData.toString(2).padStart(offerFields.length * 2, '0');
    const flags = segmentArray(offerFlags, 2).map((flag) => parseInt(flag, 2));

    offer = offerFields.reduce((acc, field, i) => ({ ...acc, [field]: flags[i] }), {}); // TODO: type for acc
  }
  const parsed = { ...payloadToInput(propertyOrders, params, defs), offer };

  if (isDev) {
    console.log(parsed.flags, parsed.values, parsed.options, parsed.offer);

    if (getSignatureV3(data.slice(0, -1), brand) !== data[data.length - 1]) {
      throw new Error('Invalid query string');
    }
  }

  return parsed;
}
