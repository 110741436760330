import { OptionOfferState } from '@/contexts/OfferContext';
import { IOption } from '@/interfaces/IOption';
import { ObjectFromList } from '@/interfaces/ObjectFromList';
import { Country } from '../interfaces/Country';

export const perks: Readonly<Array<{ key: string; label: string; countries: Array<Country> }>> = [
  // {
  //   key: 'car',
  //   label: 'common.perks.car',
  //   countries: ['hr'],
  // },
] as const;

export const perkFields = perks.map(({ key }) => key);
type PerkField = typeof perkFields;

export const perkDefaults = perkFields.reduce(
  (acc: ObjectFromList<PerkField, IOption> | Record<never, never>, key) => ({
    ...acc,
    [key]: { flag: false, value: 0 },
  }),
  {},
) as ObjectFromList<PerkField, IOption>;

export const perkVisibility = perkFields.reduce(
  (acc: ObjectFromList<PerkField, OptionOfferState> | Record<never, never>, key) => ({
    ...acc,
    [key]: OptionOfferState.Hidden,
  }),
  {},
) as ObjectFromList<PerkField, OptionOfferState>;
