import { ILegacyInputData } from '@/interfaces/ILegacyInputData';
import { Country } from '@/interfaces/Country';
import { propertyOrders } from '@/components/features/Croatia/constants/defaults';

const optimisationMapping = propertyOrders.options;
const shortenMap: Record<string, string> = { _: ',,', '\\$': ',,,' };
const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
const charsCount = chars.length;

export const keys: Partial<Record<Country, string>> = {};

function decompressNumbers(numbers: number, count: number): Array<number> {
  let nums = numbers;
  const result = [];

  for (let i = 0; i < count; i++) {
    result.push(nums % 10);
    nums = Math.floor(nums / 10);
  }

  return result.reverse();
}

function decompressFlags(flags: number, count: number): Array<boolean> {
  return flags
    .toString(2)
    .padStart(count, '0')
    .split('')
    .map((val) => val === '1');
}

function decodeNumber(code: string): number {
  let num = 0;

  for (let i = 0; i < code.length; i++) {
    num = num * charsCount + chars.indexOf(code[i]);
  }

  return num;
}

function segmentArray(arr: Array<unknown>, size: number): Array<Array<unknown>>;
function segmentArray(arr: string, size: number): Array<string>;
function segmentArray(arr: string | Array<unknown>, size: number): Array<Array<unknown> | string> {
  const result = [];

  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }

  return result;
}

export function parseV2(data: string, defs: ILegacyInputData): ILegacyInputData {
  let str = `[${data.slice(2)}]`;

  Object.entries(shortenMap).forEach(([key, value]) => {
    str = str.replace(new RegExp(key, 'g'), value);
  });
  const commas = str.split(',').filter((l: string) => l === ',').length;

  str = str.replace(']', ']'.padStart(16 - commas, ','));
  str = str.replace(/[a-zA-Z0-9]+/g, (match: string) => decodeNumber(match).toString());
  str = str.replace(/,{2,}/g, (match) => match.split('').join('0'));
  str = str.replace(',]', ',0]');
  const fromUrl = JSON.parse(str);

  const [zipped, total, stopa, ...optimisations] = fromUrl as Array<number>;
  const opts = segmentArray(optimisations, 2).map(([amount, enabled]) => ({ amount, enabled })) as Array<{
    amount: number;
    enabled: boolean;
  }>;
  const [brojDjece, brojUzdrzavanih, age, detalji, flags] = decompressNumbers(zipped, 5);
  const [mladaOsoba, chart] = decompressFlags(flags, 2).reverse();
  const params = {
    total: total,
    optimisations: optimisationMapping?.map((type, index) => ({
      type,
      amount: opts[index]?.amount ?? 0,
      enabled: Boolean(opts[index]?.enabled),
    })),
    stopa,
    age,
    brojDjece,
    brojUzdrzavanih,
    detalji,
    mladaOsoba: mladaOsoba,
    chart: chart,
  };

  return {
    total: params.total ?? defs.total,
    optimisations: params.optimisations ?? defs.optimisations,
    stopa: params.stopa ?? defs.stopa,
    brojDjece: params.brojDjece ?? defs.brojDjece,
    brojUzdrzavanih: params.brojUzdrzavanih ?? defs.brojUzdrzavanih,
    detalji: params.detalji ?? defs.detalji,
    mladaOsoba: params.mladaOsoba ?? defs.mladaOsoba,
    age: params.age ?? defs.age,
    chart: params.chart ?? defs.chart,
  };
}
