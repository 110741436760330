import { IOfferValue, OptionOfferState } from '@/contexts/OfferContext';
import { useOffer } from '@/hooks/useOffer';
import { useUrl } from '@/hooks/useUrl';
import { Brand } from '../enums/Brand';

export interface IState {
  state: Record<string, unknown> & { offer?: Record<string, OptionOfferState> };
  setField: (name: string, value: number) => void;
  serialize: (offerState?: Record<string, OptionOfferState>) => string;
}

interface IUseParamsProps<TState extends IState, IAdditionalData = Record<string, unknown>> {
  isInternal: boolean;
  isOffer: boolean;
  useTaxData: (
    brand: Brand,
    offerFields?: ReadonlyArray<string>,
    options?: IAdditionalData & Record<string, unknown>,
  ) => TState;
  offerFields: ReadonlyArray<string>;
  offerFieldDefaults: Record<string, OptionOfferState>;
  brand: Brand;
  options?: IAdditionalData & Record<string, unknown>;
}

interface IUseParamsResult<TState extends IState> {
  urlParams: string;
  offerValue: IOfferValue;
  state: TState['state'];
  setField: TState['setField'];
}

export function useParams<TState extends IState, IAdditionalData = Record<string, unknown>>({
  isInternal,
  isOffer,
  offerFields,
  offerFieldDefaults,
  useTaxData,
  brand,
  options,
}: IUseParamsProps<TState, IAdditionalData>): IUseParamsResult<TState> {
  const { state, setField, serialize } = useTaxData(brand, isOffer || isInternal ? offerFields : undefined, options);

  const offerValue = useOffer(offerFields, state?.offer || offerFieldDefaults, isInternal, isOffer);

  const urlParams = serialize(isInternal || isOffer ? offerValue?.offerState : undefined);

  useUrl(urlParams, isInternal);

  return { urlParams, offerValue, setField, state };
}
