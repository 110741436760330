import { ILegacyInputData } from '@/interfaces/ILegacyInputData';

export function parseV1(data: string, defs: ILegacyInputData): ILegacyInputData {
  const str = atob(data);
  const fromUrl = JSON.parse(str);
  const params = fromUrl;

  return {
    total: params.total ?? defs.total,
    optimisations: params.optimisations ?? defs.optimisations,
    stopa: params.stopa ?? defs.stopa,
    brojDjece: params.brojDjece ?? defs.brojDjece,
    brojUzdrzavanih: params.brojUzdrzavanih ?? defs.brojUzdrzavanih,
    detalji: params.detalji ?? defs.detalji,
    mladaOsoba: params.mladaOsoba ?? defs.mladaOsoba,
    age: params.age ?? defs.age,
    chart: params.chart ?? defs.chart,
  };
}
