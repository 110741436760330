import { useMemo, useState } from 'react';

import { IOfferValue, OptionOfferState } from '@/contexts/OfferContext';

export function useOffer(
  offerFields: ReadonlyArray<string>,
  defaultFieldsState: Record<string, OptionOfferState> = {},
  isInternal?: boolean,
  isOffer?: boolean,
): IOfferValue {
  const [offerState, setOfferState] = useState(() =>
    offerFields.reduce(
      (acc: Record<string, OptionOfferState>, field) => ({
        ...acc,
        [field]: defaultFieldsState[field] ?? (isOffer ? OptionOfferState.Hidden : OptionOfferState.Editable),
      }),
      {},
    ),
  );

  const offerValue = useMemo(
    () => ({
      offerState,
      setOfferState: (field: string, value: OptionOfferState) => {
        setOfferState({ ...offerState, [field]: value });
      },
      isInternal,
      getFieldOfferState(key: string | undefined) {
        if (!isInternal && !isOffer) {
          return {
            isHidden: false,
            isReadOnly: false,
            isEditable: true,
            isReadOnlyValue: false,
            isInternalHidden: false,
          };
        }

        const value = key ? offerState[key] : OptionOfferState.Hidden;

        return {
          isInternalHidden: Boolean(isInternal) && value === OptionOfferState.Hidden,
          isHidden: !isInternal && value === OptionOfferState.Hidden,
          isReadOnly: !isInternal && value === OptionOfferState.ReadOnly,
          isEditable: !isInternal && value === OptionOfferState.Editable,
          isReadOnlyValue: !isInternal && value === OptionOfferState.ReadOnlyValue,
        };
      },
    }),
    [offerState, isInternal, isOffer],
  );

  return offerValue;
}
